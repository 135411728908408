import { render, staticRenderFns } from "./PtBuyOrder.vue?vue&type=template&id=55569756&scoped=true&"
import script from "./PtBuyOrder.vue?vue&type=script&lang=ts&"
export * from "./PtBuyOrder.vue?vue&type=script&lang=ts&"
import style0 from "@/assets/style/order.css?vue&type=style&index=0&id=55569756&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55569756",
  null
  
)

export default component.exports